<template >
    <div>
        <section id="contact-us" class="bg-contact about section">
            <div class="container">
                <div class="row">
                    <div class="mb-5">
                        <img src="../assets/img/brand.png" width="50">
                        <div class="titulo-sobre">
                            {{ translateAll.enter }} <span class="texto-destaque">{{ translateAll.contact }}</span>
                        </div>
                        <div class="separator"></div>
                        <div class="text-card text-center">
                            <span class="mb-3">
                                {{ translateAll.visit_us }}
                            </span>
                        </div>
                    </div>

                    <div class="sub-title col-lg-6 text-start" >
                        <h3>{{ translateAll.contact_details }}</h3>
                     
                        <div class="contact-details">
                            <div class="con-info"> 
                                <i class="tf-map-pin"></i>
                                <b-icon icon="geo-alt-fill" aria-hidden="true" class="icon-contact"></b-icon> <span>Rua Professor Annes Dias, 154 – sala 402<br>Centro Histórico, Porto Alegre - RS</span>
                            </div>
                            <div class="separador"></div>

                            <div class="con-info">
                                <i class="tf-ion-ios-telephone-outline"></i>
                                <b-icon icon="telephone" aria-hidden="true" class="icon-contact"></b-icon> <span>{{ translateAll.phone }}: +55 (51) 9 8215.8942 </span>
                            </div>

                            <div class="con-info">
                                <i class="tf-ion-ios-email-outline"></i>
                                <b-icon icon="envelope" aria-hidden="true" class="icon-contact"></b-icon>Email: <a class="email-contact" href="mailto:gustavocfossa@gmail.com">gustavocfossa@gmail.com</a>
                            </div>
                        </div>
                    </div>
         
                    <div class="contact-form col-lg-6 mt-4 mt-lg-0 wow fadeInUp" data-wow-duration="500ms" data-wow-delay="300ms">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3454.231244891827!2d-51.223291!3d-30.030223!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95197907c5ddcb2f%3A0x79482bf773eed91a!2sR.%20Gen.%20Vitorino%2C%20154%20-%20Centro%20Hist%C3%B3rico%2C%20Porto%20Alegre%20-%20RS%2C%2091130-490!5e0!3m2!1spt-BR!2sbr!4v1724785143167!5m2!1spt-BR!2sbr" width="100%" height="100%" style="border:0; border-radius: 10px;"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>

        </section>
        <section class=" space-section space-site">
            <div class="d-flex justify-content-start ">
                <div class="col-lg-12 d-flex flex-wrap justify-content-evenly footer-section">
                    <a class="navbar-brand logo" href="/">
                        <span>Legacy</span> 
                        &copy; 2017
                    </a>
                    
                </div>
            </div>
        </section>
    </div>
</template>
<script>

import {eventBus} from '@/main'
import Pt from "@/language/Pt.json";
import En from "@/language/En.json";
import Es from "@/language/Es.json";

export default {

    data() {
        return {
            translateAll: [],
            translateEn: [],
            getName: '',
            getEmail: '',
            getNumber: '',
            getMessage: '',

        }
    },
    created() {
        let languageSite = new URLSearchParams(window.location.search).get("language");

        if(languageSite === "pt") {
            this.translateAll = Pt[0];

        } else if(languageSite === "en") {
            this.translateAll = En[0];

        } else if(languageSite === "es") {
            this.translateAll = Es[0];

        } else {
            this.translateAll = Pt[0];
        }

        eventBus.$on("pt", (pt) => {
            this.translateAll = pt;
        });
        eventBus.$on("en", (en) => {
            this.translateAll = en;
        });
        eventBus.$on("es", (es) => {
            this.translateAll = es;
        });

    },
}
</script>
<style >
    .bg-contact{
        background-color: #222323;
        padding: 5rem 0 5rem 0;
        font-family: "Mulish";
    }

    .sub-title h3{
        font-style: italic;
        padding: 0 0 1.5rem;
        font-family: "Mulish";
    }
    .separador{
        border: 1px solid #48b2d8;
        width: 15%;
        margin: .5rem 0 1rem;
    }
    .icon-contact{
        color: #48b2d8;
        margin: 0 1rem 0 0 ;
    }
    .email-contact{
        color: white;
        text-decoration: none;
    }
    .email-contact:hover{
        color: #65b0ed;
    }
    .con-info{
        font-size: 16px;
        margin: 8px 0;
    }
</style>