<template>
    <div>
        <section class="bg-one about section" id="about">
            <div class="container">
                <div class="row">
                    <div class="mb-5">
                        <img src="../assets/img/brand.png" width="50">
                        <div class="titulo-sobre">
                           {{ translateAll.who_are }} <span class="texto-destaque">{{ translateAll.we }}</span>
                        </div>
                        <div class="separator"></div>
                        <div class="text-card">
                            <span class="mb-3 text-center">
                                <div v-html="translateAll.believes_that_going"></div>
                            </span>
                            <span class="text-center">
                                <div v-html="translateAll.our_purpose"></div>
                            </span>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap">
                        <div class="col-lg-6 col-12 p-3">
                            <div class="card-about">
                                <img src="../assets/img/gustavo.jpeg" alt="" class="img-fluid" />
                                <div class="title-card">Dr. Gustavo Fossá</div>
                                <div class="text-card">
                                    {{ translateAll.gustavo_history }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12 p-3">
                            <div class="card-about">
                                <img src="../assets/img/aline.jpeg" alt="" class="img-fluid" />
                                <div class="title-card">{{ translateAll.dr }} Aline Fossá</div>
                                <div class="text-card">
                                    {{ translateAll.aline_story }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div> 
</template>

<script>
import { eventBus } from "@/main";
import Pt from "@/language/Pt.json";
import En from "@/language/En.json";
import Es from "@/language/Es.json";

export default {
    name: "About",
    data() {
        return {
            translateAll: [],
        };
    },
    created() {
        let languageSite = new URLSearchParams(window.location.search).get("language");

        if(languageSite === "pt") {
            this.translateAll = Pt[0];

        } else if(languageSite === "en") {
            this.translateAll = En[0];

        } else if(languageSite === "es") {
            this.translateAll = Es[0];

        } else {
            this.translateAll = Pt[0];
        }

        eventBus.$on("pt", (pt) => {
            this.translateAll = pt;
        });
        eventBus.$on("en", (en) => {
            this.translateAll = en;
        });
        eventBus.$on("es", (es) => {
            this.translateAll = es;
        });

    },
};
</script>
<style>
.bg-one {
    background-color: #252525;
    padding: 5rem 0;
    background-image: url('../assets/img/background.png');
    background-repeat: repeat;
    background-size: cover;
}

.color {
    color: #48b2d8;
    font-family: "Georgia Pro CondBold" !important;
}

.separator {
    border: 1px solid #9fc6eb33 !important;
    margin: 10px 0;
}

.card-about {
    background-color: #191a17;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 10px 15px 10px #00000028;
    border-bottom: 2px solid transparent;
    padding: 1rem;
    border-radius: 8px;
}

.card-about img {
    border-radius: 8px;
}

.card-about:hover>.rounded-circle {
    position: relative;
    top: -1px;
}

/* .card-about:hover {
    border-bottom: 2px solid #48b2d8;
} */
.title-card {
    color: #48b2d8;
    font-size: 26px;
    padding: 0 25px;
    margin: 25px 0;
    text-align: start;
    border-bottom: 1px solid #48b2d8;
    width: 100%;
    font-family: "Mulish";
    font-weight: 800;
}

.text-card {
    font-size: 18px;
    margin: 0 0.2rem 0.2rem;
    color: #e2e2e2;
    font-family: "Mulish";
    text-align: start;
    display: flex;
    flex-direction: column;
}

.border-img {
    border: 1px solid #dbd7d7;
    padding: 0.2rem;
}

.titulo-sobre {
    font-size: 42px;
    display: flex;
    text-align: start;
    justify-content: center;
    font-family: "Georgia Pro bold";
    flex-wrap: wrap;
}

.texto-destaque {
    color: #8bdefc;
    margin-left: 10px;
}

@media (max-width: 1024px) {
    .titulo-sobre {
        font-size: 32px;
    }
}
</style>
