<template>
    <div class="home" >
        <Home />
        <About />
        <Jobs />
        <Contact />

    </div>

</template>

<script>
import Home from '@/components/Home.vue'
import About from '@/components/About.vue'
import Jobs from '@/components/Jobs.vue'
import Contact from '@/components/Contact.vue'


export default {
    components: {
        Home,
        About,
        Jobs,
        Contact
    },
  
}
</script>
<style>
.history {
    padding: 1rem 0 2rem;
    font-size: 20px;
    font-style: italic;
    font-family: 'Georgia Pro bold';
}

</style>
