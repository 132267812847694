<template>
    <div>
        <section id="our-solution" class="section bg-two section">
            <div class="container">
                <div class="row">
                    <div class="mb-5">
                        <img src="../assets/img/brand.png" width="50">
                        <div class="titulo-sobre">{{ translateAll.our == 'Nossa' ? 'Nossas' : translateAll.our }} <span
                                class="texto-destaque">especialidades</span></div>
                        <div class="separator"></div>
                        <div class="text-card " :class="sizeWidth > 770 ? 'text-center' : 'text-start'">
                            <span class="mb-3">
                                {{ translateAll.highest_qualifications }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="d-flex justify-content-center flex-wrap">
                        <div class="card-func " v-for="item in especialidades">
                            <img :src="item.img" />
                            <div class="text-center pl-3">
                                <span class="text-card-title">{{ item.name }}</span>
                                <span class="text-card-desc">{{ item.description }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
        <section id="galeria" class=" bg-one">
            <div class="container">
                <div class="row">
                    <div class="mb-5">
                        <img src="../assets/img/brand.png" width="50">
                        <div class="titulo-sobre">{{ translateAll.our }} <span class="texto-destaque">{{ translateAll.galery }}  </span></div>
                        <div class="separator"></div>
                        <div class="text-card " :class="sizeWidth > 770 ? 'text-center' : 'text-start'">
                            <span class="mb-3">
                                {{ translateAll.most_qualified }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center flex-column align-items-center ">
                <div class="col-12 col-lg-10 col-md-12 d-flex flex-wrap justify-content-center ">
                    <div class="mx-1">
                        <div class="image-container">
                            <img src="../assets/img/galeria/img4.jpeg" class="img-fluid sty-img custom-image">
                            <div class="custom-overlay">
                                <div class="overlay-text ">{{ translateAll.botulinum_toxin_application }}</div>
                            </div>
                        </div>
                    </div>
                    <div class=" d-flex flex-column">
                        <div class="mx-1 mb-2">
                            <div class="image-container">
                                <img src="../assets/img/galeria/img5_1.jpeg" class="img-fluid sty-img custom-image">
                                <div class="custom-overlay">
                                    <div class="overlay-text">{{ translateAll.lip_filling_with_hyaluronic_acid }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="mx-1 ">
                            <div class="image-container">
                                <img src="../assets/img/galeria/img5_2.jpeg" class="img-fluid sty-img custom-image">
                                <div class="custom-overlay">
                                    <div class="overlay-text">{{ translateAll.lip_filling_with_hyaluronic_acid }} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mx-1">
                        <div class="image-container">
                            <img src="../assets/img/galeria/img6.jpeg" class="img-fluid sty-img custom-image">
                            <div class="custom-overlay">
                                <div class="overlay-text">{{ translateAll.lip_filling_with_hyaluronic_acid_in_lips }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="mx-1">
                        <div class="image-container">
                            <img src="../assets/img/galeria/img1.jpeg" class="img-fluid sty-img custom-image">
                            <div class="custom-overlay">
                                <div class="overlay-text">{{ translateAll.botulinum_toxin_application }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="mx-1 mt-2">
                        <div class="image-container">
                            <img src="../assets/img/galeria/img3.jpeg" class="img-fluid sty-img custom-image">
                            <div class="custom-overlay">
                                <div class="overlay-text">{{ translateAll.botulinum_toxin_application }}</div>
                            </div>
                        </div>
                    </div>
                    <div class=" d-flex flex-column mt-2">
                        <div class="mx-1 mb-2">
                            <div class="image-container">
                                <img src="../assets/img/galeria/img7.jpeg" class="img-fluid sty-img custom-image">
                                <div class="custom-overlay">
                                    <div class="overlay-text">{{ translateAll.ceramic_protocol }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="mx-1">
                            <div class="image-container">
                                <img src="../assets/img/galeria/img12.jpeg" class="img-fluid sty-img custom-image">
                                <div class="custom-overlay">
                                    <div class="overlay-text">{{ translateAll.ceramic_veneers }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" d-flex flex-column mt-2">
                        <div class="mx-1 mb-2">
                            <div class="image-container">
                                <img src="../assets/img/galeria/img9.jpeg" class="img-fluid sty-img custom-image">
                                <div class="custom-overlay">
                                    <div class="overlay-text">{{ translateAll.resin_onlays }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="mx-1">
                            <div class="image-container">
                                <img src="../assets/img/galeria/img10.jpeg" class="img-fluid sty-img custom-image">
                                <div class="custom-overlay">
                                    <div class="overlay-text">{{ translateAll.resin_onlays }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mx-1 mt-2">
                        <div class="image-container">
                            <img src="../assets/img/galeria/img14.jpeg" class="img-fluid sty-img custom-image">
                            <div class="custom-overlay">
                                <div class="overlay-text">{{ translateAll.at_home_whitening }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="mx-1 my-2">
                        <div class="image-container">
                            <img src="../assets/img/galeria/img11.jpeg" class="img-fluid sty-img custom-image">
                            <div class="custom-overlay">
                                <div class="overlay-text">{{ translateAll.ceramic_veneers }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="mx-1 my-2">
                        <div class="image-container">
                            <img src="../assets/img/galeria/img13.jpeg" class="img-fluid sty-img custom-image">
                            <div class="custom-overlay">
                                <div class="overlay-text">{{ translateAll.in_office_whitening }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" col-12 col-lg-6 col-md-10">
                    <div class="d-flex justify-content-center">
                        <div class="align-btn">
                            <a class="btn-transparent" target="_blank"
                                href="https://wa.me/5551982158942?text=Olá,%20gostaria%20de%20marcar%20uma%20consulta%20!">{{
                                    translateAll.schedule_consultation }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { eventBus } from "@/main";
import Pt from "@/language/Pt.json";
import En from "@/language/En.json";
import Es from "@/language/Es.json";

export default {
    data() {
        return {
            translateAll: [],

            especialidades: [],
            sizeWidth: window.innerWidth,
        };
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    created() {
        let languageSite = new URLSearchParams(window.location.search).get("language");

        if (languageSite === "pt") {
            this.translateAll = Pt[0];

        } else if (languageSite === "en") {
            this.translateAll = En[0];

        } else if (languageSite === "es") {
            this.translateAll = Es[0];

        } else {
            this.translateAll = Pt[0];
        }

        eventBus.$on("pt", (pt) => {
            this.translateAll = pt;
            this.jobs()
        });
        eventBus.$on("en", (en) => {
            this.translateAll = en;
            this.jobs()
        });
        eventBus.$on("es", (es) => {
            this.translateAll = es;
            this.jobs()
        });

        this.jobs()
    },
    methods: {
        handleResize() {
            this.sizeWidth = window.innerWidth;
        },
        jobs() {
            this.especialidades = [
                {
                    name: this.translateAll.endodontics,
                    description: this.translateAll.endodontics_description,
                    img: require("../assets/image/trabalhos/endodontia.svg"),
                },
                {
                    name: this.translateAll.periodontics,
                    description: this.translateAll.periodontics_description,
                    img: require("../assets/image/trabalhos/periodontia.svg"),
                },
                {
                    name: this.translateAll.facial_harmonization,
                    description: this.translateAll.facial_harmonization_description,
                    img: require("../assets/image/trabalhos/harmonizacao.svg"),
                },
                {
                    name: this.translateAll.dental_veneers,
                    description: this.translateAll.dental_veneers_description,
                    img: require("../assets/image/trabalhos/faceta-dentaria.svg"),
                },
                {
                    name: this.translateAll.teeth_whitening,
                    description: this.translateAll.teeth_whitening_description,
                    img: require("../assets/image/trabalhos/clareamento.svg"),
                },
                {
                    name: this.translateAll.prosthetics,
                    description: this.translateAll.prosthetics_description,
                    img: require("../assets/image/trabalhos/protese.svg"),
                },
                {
                    name: this.translateAll.implants,
                    description: this.translateAll.implants_description,
                    img: require("../assets/image/trabalhos/implante.svg"),
                },
                {
                    name: this.translateAll.bruxism,
                    description: this.translateAll.bruxism_description,
                    img: require("../assets/image/trabalhos/default.svg"),
                },

                {
                    name: this.translateAll.oral_surgery_traumatology,
                    description: this.translateAll.oral_surgery_traumatology_description,
                    img: require("../assets/image/trabalhos/cirurgia.svg"),
                },

            ]
        }
    },
};
</script>
<style>
.bg-two {
    padding: 5rem 0;
}

.card-func {
    margin: 15px 15px;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px;
    width: 400px;
    background-color: #1a1a18;
    border-bottom: 1px solid transparent;
}

.card-func:hover {
    border-bottom: 1px solid #9fc9eb;
    position: relative;
    top: 1px;
}

.text-card-title,
.text-card-desc {
    font-size: 18px;
    margin: 0 0.2rem;
    color: #9fd3eb;
    font-family: "Mulish";
    text-align: start;
    display: flex;
}

.text-card-desc {
    font-size: 16px;
    margin: 7px 0 15px;
    color: #e2e2e2;
}

.sty-img {
    border: 1px solid #9fd6eb;
}

.org-jobs {
    width: 80%;
}

.org-jobs img {
    border-radius: 7px;
}

.txt-destaque-jobs {
    font-size: 2rem;
    color: #ebd79f;
    font-weight: bold;
}


.image-container {
    position: relative;
    /* Ajuste conforme necessário */
}

.custom-image {
    display: block;
    width: 100%;
    height: auto;
}

.custom-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.795);
    /* Tarja preta semi-transparente */
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: height 0.5s ease;
}

.overlay-text {
    color: white;
    font-size: 22px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    opacity: 0;;  
    /* Inicialmente invisível */
    transition: opacity 0.5s ease;
    text-wrap: wrap;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.image-container:hover .custom-overlay {
    height: 100%;

}

.image-container:hover .overlay-text {
    opacity: 1;

    /* Tornar visível no hover */
}
</style>
